@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(40%);
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fade-in-text {
    animation: fadeIn 2s; 
  }
  .fade-in-2text {
    animation: fadeIn 4s; 
  }
  
        

        
